import React from "react";
// import SpectatorDisplay from "../components/SpectatorDisplay";
function Spectator() {
  return (
    <div className="spectator">
      <h1>Je suis spectateur</h1>
      <div className="vignette txt">
        <div className="flexRow">
          <h2>La Cité Idéale</h2>
          <p>Création 2022 - Durée : 50 min</p>
        </div>
        <img src="/citeideale.webp" alt="" />
        <p className="italic">
          Tout public à partir de 14 ans De Pierre Koestel Mise en scène -
          Jérémy Ridel Avec - Charlotte Berthemet, Angèle Peyrade et Laure
          Prioul
        </p>
        <p>
          Un quartier de banlieue, en pleine mutation. Différents projets de
          réaménagement se développent, pour moderniser et renouveler les
          infrastructures locales. Une habitante lutte contre ces promesses
          d'innovation, parce qu'elle risque de tout perdre. La Cité idéale
          raconte son histoire et interroge son combat : jusqu'où faut-il aller
          pour défendre ses convictions ? Quand est-ce qu'on va trop loin ?
        </p>
      </div>
      <div className="vignette txt">
        <div className="flexRow">
          <h2>La Bette</h2>
          <p>Création 2023 (Spectacle en salle) - Durée : 1h30</p>
        </div>

        <img src="labette.webp" alt="" />

        <p className="italic">
          A partir de 15 ans / Mise en scène - Jérémy Ridel / Avec - Charlotte
          Berthemet, Daniel Monino, Angèle Peyrade, Laure Prioul, Antoine
          Prud’homme de la Boussinière et Simon Rembado.
        </p>
        <p>
          La Cousine Bette est le récit d’une vengeance implacable, celle d’une
          femme dite laide, Lisbeth Fischer, qui travaille à la destruction
          systématique d’une famille – sa famille.
        </p>
      </div>
      <div className="vignette txt">
        <h2>Restitution</h2>
        <p>
          La Cousine Bette est le récit d’une vengeance implacable, celle d’une
          femme dite laide, Lisbeth Fischer, qui travaille à la destruction
          systématique d’une famille – sa famille.
        </p>
      </div>
    </div>
  );
}

export default Spectator;
