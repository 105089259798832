import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Permet de se connecter avec l'email et le password
  //  et stock les informations de l'utilisater
  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        },
        {
          withCredentials: true, // Ajout de withCredentials ici
        }
      )
      .then((res) => {
        navigate("/");
      })
      .catch((err) => setErrorMessage("Create account failed"));
  };
  return (
    <div className="login">
      <form onSubmit={(e) => handleLogin(e)} className="sendPost">
        <h2>LOGIN</h2>
        <br />
        <p>Email</p>
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name=""
        />
        <p>Password</p>
        <input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          name=""
        />
        {errorMessage && <p className="erroMessage">Unknown user</p>}
        <br />
        <br />
        <input type="submit" value="LOGIN" />
      </form>
    </div>
  );
};
export default Login;
