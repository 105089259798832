import axios from "axios";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import ExprimeDelete from "./ExprimeDelete";
// import { useCookies } from "react-cookie";
import useFetchLieux from "../../function/useFetchLieux";

const extractPodcastNumber = (url) => {
  const match = url.match(/\/podcast\/(\d+)\//);
  return match && match[1] ? match[1] : null;
};

function ExprimePost(data) {
  const [posts, setPosts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditTitle] = useState("");
  const [editedTypeAtelier, setEditTypeAtelier] = useState("");
  const [userName, setUserName] = useState(null);
  const [editedIntervenant, setEditIntervenant] = useState("");
  const [editedPodcast, setEditPodcast] = useState("");
  const [newsData, setNewsData] = useState([]);
  const [editedText, setEditText] = useState("");
  const [editedImage, setEditImage] = useState(null);
  const [lieux, setLieux] = useState([]);
  const [idlieu, setIdLieu] = useState([]);
  const [editedLatitude, setEditedLatitude] = useState(data.post.latitude);
  const [editedLongitude, setEditedLongitude] = useState(data.post.longitude);

  // const [cookies, setCookie] = useCookies(["cookieName"]);
  // setCookie("cookieName", "value", { sameSite: "None", secure: true });

  const date = new Date(data.post.createdAt);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("fr-FR", options);

  // Cette fonction permet à l'admin de modifier les posts
  useEffect(() => {
    getData();
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/userinfo`, {
        withCredentials: true,
      })
      .then((response) => {
     
        if (response.data) {
          setUserName(response.data.username);
          console.log("Utilisateur connecté:");
        } else {
          setUserName(null);
          console.log("Aucune donnée d'utilisateur reçue");
        }
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des informations utilisateur:",
          error
        );
        if (error.response && error.response.status === 401) {
          console.log("Utilisateur non connecté ou session expirée");
          setUserName(null);
        }
      });
  }, []);

  const modifLieu = useFetchLieux();

  const getData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts/`, {
        withCredentials: true,
      });
      setNewsData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLieuChange = (e) => {
    const selectedLieuId = e.target.value;
    setIdLieu(selectedLieuId);

    const lieuSelectionne = modifLieu.find(
      (l) => l.id.toString() === selectedLieuId
    );
    if (lieuSelectionne) {
      setEditedLatitude(lieuSelectionne.latitude);
      setEditedLongitude(lieuSelectionne.longitude);
      setLieux(lieuSelectionne.lieu);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    // Trouver le lieu sélectionné par son ID
    let lieuSelectionne = modifLieu.find((l) => l.id.toString() === idlieu);
    if (!lieuSelectionne) {
      alert("Veuillez sélectionner un lieu valide.");
      return;
    }
    // Récupérer les coordonnées
    const podcastNumber = extractPodcastNumber(editedPodcast);
    let processedPodcast; // variable pour stocker le podcast traité

    if (podcastNumber) {
      processedPodcast = `https://audioblog.arteradio.com/embed/${podcastNumber}`;
    } else {
      alert("URL du podcast invalide !");
      return;
    }
    const formData = new FormData();

    formData.append("title", editedTitle ? editedTitle : data.post.title);
    formData.append(
      "typeAtelier",
      editedTypeAtelier ? editedTypeAtelier : data.post.typeAtelier
    );
    formData.append(
      "intervenant",
      editedIntervenant ? editedIntervenant : data.post.intervenant
    );
    formData.append("podcast", processedPodcast);
    formData.append("text", editedText ? editedText : data.post.text);
    formData.append("lieu", lieux);
    formData.append("latitude", editedLatitude);
    formData.append("longitude", editedLongitude);

    // Ajouter l'image modifiée ou conserver l'URL existante
    if (editedImage) {
      formData.append("image", editedImage);
    } else {
      // Si aucune nouvelle image n'est sélectionnée, envoyer l'URL existante
      formData.append("imageUrl", data.post.imageUrl);
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/posts/` + data.post.id, formData, {
        withCredentials: true,
      })
      .then((res) => {
        setPosts(res.data);
        setIsEditing(false); // Mettre fin au mode d'édition
        // Optionnel : recharger les données ou mettre à jour l'état pour refléter les changements
      })
      .catch((err) => {
        console.error("Erreur lors de la mise à jour du post", err);
        // Gérer l'erreur ici
      });
  };

  return (
    <>
      {userName ? (
        <div className="vignette" key={data.post.id}>
          <div className="buttonHorizontal">
            {isEditing ? (
              <button onClick={handleEdit}>Valider</button>
            ) : (
              <button onClick={() => setIsEditing(true)}>
                <i className="far fa-edit">Éditer</i>
              </button>
            )}
            <ExprimeDelete id={data.post.id} />
          </div>

          <>
            {isEditing ? (
              <>
                {/* Edition du poste */}
                <div className="">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/png,image/jpeg,image/gif"
                    style={{ display: "none" }}
                    onChange={(e) => setEditImage(e.target.files[0])}
                  />
                  <label htmlFor="fileInput" className="button-style">
                    Changer d'image
                  </label>
                  <h2>Titre de l'atelier</h2>
                  <textarea
                    onChange={(e) => setEditTitle(e.target.value)}
                    autoFocus
                    defaultValue={editedTitle ? editedTitle : data.post.title}
                  ></textarea>
                  <p>{data.post.createdAt}</p>
                </div>

                <div className="vignetteInfo">
                  <p>
                    <b>Atelier : </b>
                    <textarea
                      onChange={(e) => setEditTypeAtelier(e.target.value)}
                      autoFocus
                      defaultValue={
                        editedTypeAtelier
                          ? editedTypeAtelier
                          : data.post.typeAtelier
                      }
                    ></textarea>
                  </p>
                  <p>
                    <b>Lieu : </b>
                    <select onChange={handleLieuChange} value={idlieu}>
                      <option value="">{data.post.lieu}</option>
                      {modifLieu.map((lieu) => (
                        <option key={lieu.id} value={lieu.id}>
                          {lieu.lieu}
                        </option>
                      ))}
                    </select>
                  </p>
                  <p>
                    <b>Intervenant : </b>
                    <textarea
                      onChange={(e) => setEditIntervenant(e.target.value)}
                      autoFocus
                      defaultValue={
                        editedIntervenant
                          ? editedIntervenant
                          : data.post.intervenant
                      }
                    ></textarea>
                  </p>
                </div>
                <p>
                  <b>Lien du podcast</b>
                </p>
                <textarea
                  onChange={(e) => setEditPodcast(e.target.value)}
                  autoFocus
                  defaultValue={
                    editedPodcast ? editedPodcast : data.post.podcast
                  }
                ></textarea>
                <Iframe
                  width="100%"
                  src={data.post.podcast}
                  styles={{ border: "0px solid red" }}
                />
              </>
            ) : (
              // visible entant que admin
              <>
                <img className="imgPost" src={data.post.imageUrl} alt="" />
                <div className="vignetteTitle">
                  <h1>{data.post.title}</h1>
                </div>
                <div className="vignetteInfo">
                  <p>
                    <b>Lieu : </b>
                    {data.post.lieu}
                  </p>
                  <p>
                    <b>Intervenant : </b>
                    {data.post.intervenant}
                  </p>
                  <p className="textProperty">{data.post.text}</p>
                </div>
                <Iframe
                  width="100%"
                  scrolling="no"
                  src={data.post.podcast}
                  style={{ margin: 0, padding: 0, border: "none" }}
                  title="Audio Blog"
                />
              </>
            )}
          </>
        </div>
      ) : (
        // Visible par l'utilisateur
        <div className="vignette" key={data.post.id}>
          <img className="imgPost" src={data.post.imageUrl} alt="" />
          <div className="vignetteTitle">
            <h1>{data.post.title}</h1>
          </div>
          <div className="vignetteInfo">
            <p>
              <b>Lieu : </b>
              {data.post.lieu}
            </p>
            <p>
              <b>Intervenant : </b>
              {data.post.intervenant}
            </p>
            <p className="textProperty">{data.post.text}</p>
          </div>
          <Iframe
            width="100%"
            scrolling="no"
            src={data.post.podcast}
            style={{ margin: 0, padding: 0, border: "none" }}
            title="Audio Blog"
          />
        </div>
      )}
    </>
  );
}

export default ExprimePost;
