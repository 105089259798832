import React from "react";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <TwitterIcon
          onClick={() => window.open("https://twitter.com/oswaldthefirst")}
        />
        <FacebookIcon
          onClick={() =>
            window.open("https://www.facebook.com/FullFrontalTheatre")
          }
        />
        <InstagramIcon
          onClick={() =>
            window.open("https://www.instagram.com/fullfrontaltheatre/")
          }
        />
      </div>
      <div className="navWidth">
        <ul className="navFooter">
          <li>
            <Link className="navColor" to="/team">
              L'équipe
            </Link>
          </li>
          <li>|</li>
          <li>
            <Link className="navColor" to="/clea">
              Cléa
            </Link>
          </li>
          <li>|</li>
          <li>
            <Link className="navColor" to="/contact">
              Nous contacter
            </Link>
          </li>
        </ul>
      </div>
      <Link to="/Login">
        <p id="copyright"> &copy; 2023 | Full Frontal Theatre</p>
      </Link>
    </div>
  );
}

export default Footer;
