import React from "react";

function Clea() {
  return (
    <div className="clea">
      <h1>Le CLéa</h1>
      <p>
        Dans le cadre de l’édition 2022-2024 du Contrat local d'éducation
        artistique et culturel (CLÉA) porté par la Communauté urbaine Grand
        Paris Seine & Oise, les collectifs W.O.R.K et V.I.D.D.A,
        FullFrontalTheatre et Maraudeurs et Compagnie sont invités à porter leur
        regard sur l’identité du territoire afin de faire découvrir aux
        habitants des facettes insoupçonnées de notre environnement quotidien.
      </p>
      <a href="https://gpseo.fr/vivre-et-habiter/culture-sport-et-tourisme/actions-educatives/lancement-du-clea-2022-2024">
        Le Cléa
      </a>
    </div>
  );
}

export default Clea;
