import React, { useEffect, useState } from "react";
import axios from "axios";

function Workshop() {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    // Supposons que l'URL "/auth/userinfo" est votre endpoint pour récupérer les infos utilisateur
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/userinfo`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data && response.data.username) {
          setUserName(response.data.username);
        }
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des informations utilisateur:",
          error
        );
        // Gérer l'erreur comme vous le souhaitez
      });
  }, []);

  return (
    <div>
      <p>caca</p>
      {userName && <h1>Bienvenue, {userName}</h1>}
      {/* Autres éléments de votre composant */}
    </div>
  );
}

export default Workshop;
