import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import geojsonData from "../data/fft.json"; // Assurez-vous que le chemin est correct
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import axios from "axios";
import Iframe from "react-iframe";

function Map() {
  const [selectedPlace, setSelectedPlace] = useState(null);
  // const [newsData, setNewsData] = useState([]);
  const [groupedMarkers, setGroupedMarkers] = useState({});
  const mapRef = useRef();

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts/`);
        // setNewsData(res.data);
        groupMarkers(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  const handleMarkerClick = (groupData, lat, lng) => {
    setSelectedPlace(groupData);
    if (mapRef.current) {
      mapRef.current.flyTo([lat, lng], 12, { duration: 2 }); // 13 est le niveau de zoom, ajustez selon vos besoins
    }
  };

  const handleCloseClick = () => {
    setSelectedPlace(null);
  };

  const groupMarkers = (data) => {
    const grouped = {};
    data.forEach((lieu) => {
      const key = `${lieu.latitude},${lieu.longitude}`;
      if (!grouped[key]) {
        grouped[key] = {
          name: lieu.lieu, // Assurez-vous que le champ est correct
          items: [],
        };
      }
      grouped[key].items.push(lieu);
    });
    setGroupedMarkers(grouped);
  };

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  const position = [48.98247022887956, 1.841583251953125];
  // Limite du zoom
  const minZoomLevel = 10; // Minimum zoom level
  const maxZoomLevel = 15; // Maximum zoom level

  // Définir de nouvelles limites pour maxBounds
  // Ajustez ces valeurs selon vos besoins pour permettre un plus grand déplacement
  const extendedBounds = [
    [48.5, 1.5], // Sud-Ouest de la carte
    [49.5, 2.2], // Nord-Est de la carte
  ];

  useEffect(() => {
    if (mapRef.current && geoJsonRef.current) {
      const geoJsonBounds = geoJsonRef.current.getBounds();
      mapRef.current.fitBounds(geoJsonBounds);
    }
  }, []);

  const geoJsonRef = useRef();

  return (
    <div className="map-container">
      <MapContainer
        className="leaflet-map"
        center={position}
        zoom={11.4}
        minZoom={minZoomLevel}
        maxZoom={maxZoomLevel}
        maxBounds={extendedBounds}
        maxBoundsViscosity={0.2}
        zoomControl={false}
        dragging={true}
        boxZoom={true}
        ref={mapRef}
      >
        <TileLayer
          url={`https://tiles.stadiamaps.com/tiles/stamen_toner_background/{z}/{x}/{y}{r}.png?key=${process.env.REACT_APP_MAP_API}`}
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        />
        <GeoJSON
          ref={geoJsonRef}
          data={geojsonData}
          style={() => ({
            color: "red",
            weight: 3,
          })}
        />
        {Object.entries(groupedMarkers).map(([key, groupData]) => {
          const [lat, lng] = key.split(",").map((coord) => parseFloat(coord));
          return (
            <Marker
              key={key}
              position={[lat, lng]}
              eventHandlers={{
                click: () => handleMarkerClick(groupData, lat, lng),
              }}
            />
          );
        })}
      </MapContainer>

      {selectedPlace && (
        <div className="info-panel">
          <button onClick={handleCloseClick} className="close-button">
            Fermer
          </button>
          <h2>{selectedPlace.name}</h2>
          {selectedPlace.items.map((item, idx) => (
            <div key={idx}>
              <h3>{item.title}</h3>
              <Iframe
                width="100%"
                src={item.podcast}
                style={{ border: "0px solid red" }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Map;
