import React from "react";

function Atelier() {
  return (
    <div className="atelier">
      <h1>Je fais des ateliers</h1>
      <div className="vignette txt">
        <h2>Odyssées intérieures</h2>
        <p>
          Cet atelier prendra d’abord la forme d’une enquête menée par les
          participants : à travers des explorations urbaines, des recherches
          d’archives ou des entretiens avec des habitants, nous souhaitons
          amener les participants à approfondir leurs connaissances de leur
          lieux de vie. Après quelques séances d'initiation à l'écriture, les
          participants seront invités à choisir un espace particulier (une rue,
          un banc, un champ, une cour d'école, la façade d'un immeuble...) pour
          écrire une courte fiction qui le raconte. Ils devront ensuite
          enregistrer leurs textes et procéder à un travail de montage sonore,
          pour leur donner forme et voix. De lieu en lieu, c'est donc tout un
          itinéraire poétique et décalé auquel nous serons invités, pour
          appréhender et regarder ces espaces d'une toute autre façon. Ces
          objets sonores pourront être diffusés dans le contexte d’une
          plateforme Web, d’une déambulation au casque ou dans le cadre de
          podcasts.
        </p>
      </div>
      <div className="vignette txt">
        <h2>Déesses</h2>
        <p>
          Le projet Déesses a été pensé pour des publics féminins en situation
          spécifique (pénitentiaire, judiciaire ou issu du champ de l’action
          sociale, la constitution du groupe doit se faire en partenariat avec
          les acteurs locaux). A travers les figures de femmes puissantes issues
          des mythologies anciennes et contemporaines et par le biais de
          l’improvisation et du jeu, nous souhaitons accompagner les
          participantes dans une réflexion personnelle et collective sur
          l’émancipation, les obstacles rencontrés au cours d’un parcours de
          vie, les processus de guérison et de “résilience” en leur proposant de
          découvrir et d’explorer des figures de femmes marquantes.
        </p>
      </div>
      <div className="vignette txt">
        <h2>Trouve ta voix !</h2>
        <p>
          Dans la continuité de notre engagement auprès de jeunes collégiens et
          lycéens de filières générale et professionnelle, nous souhaitons
          proposer une action artistique à destination de jeunes adolescents
          dans le cadre scolaire ou institutionnel. Cet atelier, nommé Trouve ta
          voix!, s’articule autour de l’élocution et de son impact sur l’image
          de soi. A travers un parcours d’écriture et de jeu, les participants
          seront amenés à questionner l’impact de la voix, de l’élocution et de
          la prise de parole dans l’image que l’on renvoie. La construction de
          personnages multiples et la pratique de la lecture à haute voix seront
          autant de biais permettant de comprendre l’importance de la prise de
          parole dans la construction de soi et dans son rapport au monde.
        </p>
      </div>
    </div>
  );
}

export default Atelier;
