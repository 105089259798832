import axios from "axios";
import React, { useEffect, useState } from "react";
import GetUnique from "../function/GetUnique";
import ExprimePost from "../components/exprimePost/ExprimePost";
import useFetchLieux from "../function/useFetchLieux";

const extractPodcastNumber = (url) => {
  const match = url.match(/\/podcast\/(\d+)\//);
  return match && match[1] ? match[1] : null;
};
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        {children}
        <button onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

function Exprime() {
  const [newsData, setNewsData] = useState([]);
  const [userName, setUserName] = useState(null); // Remplacez `user` par `userName`
  const [userId, setUserId] = useState(null);
  const [title, setTitle] = useState([]);
  const [typeAtelier, settypeAtelier] = useState([]);
  const [idlieu, setIdLieu] = useState([]);
  const [intervenant, setIntervenant] = useState([]);
  const [podcast, setPodcast] = useState([]);
  const [place, setPlace] = useState("");
  const [atelier, setAtelier] = useState("");
  const [text, setText] = useState([]);
  const [filename, setFilename] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getData();
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/userinfo`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data) {
          setUserName(response.data.username);
          setUserId(response.data.userId);
        } else {
          setUserName(null);
          setUserId(null);
          console.log("Aucune donnée d'utilisateur reçue");
        }
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des informations utilisateur:",
          error
        );
        if (error.response && error.response.status === 401) {
          console.log("Utilisateur non connecté ou session expirée");
          setUserName(null);
          setUserId(null);
        }
      });
  }, []);

  const lieux = useFetchLieux();

  const getData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts/`);
      setNewsData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Trouver le lieu sélectionné par son ID
    let lieuSelectionne = lieux.find((l) => l.id.toString() === idlieu);
    if (!lieuSelectionne) {
      alert("Veuillez sélectionner un lieu valide.");
      return;
    }
    // Récupérer les coordonnées
    const { latitude, longitude } = lieuSelectionne;

    // Traitez l'URL du podcast avant de l'ajouter à vos données
    const podcastNumber = extractPodcastNumber(podcast);

    let processedPodcast; // variable pour stocker le podcast traité

    if (podcastNumber) {
      processedPodcast = `https://audioblog.arteradio.com/embed/${podcastNumber}`;
    } else {
      alert("URL du podcast invalide !");
      return;
    }

    let file = filename;
    let data = new FormData();
    data.append("title", title);
    data.append("typeAtelier", typeAtelier);
    data.append("lieu", lieuSelectionne.lieu);
    data.append("intervenant", intervenant);
    data.append("podcast", processedPodcast);
    data.append("text", text);
    data.append("image", file);
    data.append("id", userId);
    data.append("latitude", latitude); // Latitude du lieu
    data.append("longitude", longitude); // Longitude du lieu
    axios
      .post(`${process.env.REACT_APP_API_URL}/posts/`, data, {
        withCredentials: true,
      })
      .then(() => {
        setTitle("");
        settypeAtelier("");
        setIntervenant("");
        setPodcast("");
        setText("");
        setFilename("");
        getData();
      });
  };

  return (
    <div className="vignetteDisplay">
      <h1>Je m'exprime ici</h1>

      {userName ? (
        <>
          <button onClick={() => setIsModalOpen(true)}>
            Créer un Nouveau Post
          </button>

          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div>
              <form className="sendPost" onSubmit={(e) => handleSubmit(e)}>
                <h2>Nouveau post : </h2>
                <input
                  onChange={(e) => setFilename(e.target.files[0])}
                  type="file"
                  name="file"
                  id="file"
                  accept=".jpg, .jpeg, .png"
                />
                <input
                  label="Outlined"
                  onChange={(e) => setTitle(e.target.value)}
                  type="Titre du post"
                  placeholder="Titre... "
                  value={title}
                />
                <select onChange={(e) => setIdLieu(e.target.value)}>
                  <option value="">Sélectionnez un lieu</option>
                  {lieux.map((lieu) => (
                    <option key={lieu.id} value={lieu.id}>
                      {lieu.lieu}
                    </option>
                  ))}
                </select>
                <input
                  label="Outlined"
                  onChange={(e) => setIntervenant(e.target.value)}
                  type="Titre"
                  placeholder="Intervenant"
                  value={intervenant}
                  // type="text"
                />
                <input
                  label="Outlined"
                  onChange={(e) => settypeAtelier(e.target.value)}
                  type="Titre"
                  placeholder="Type d'atelier"
                  value={typeAtelier}
                  // type="text"
                />
                <textarea
                  label="Outlined"
                  onChange={(e) => setText(e.target.value)}
                  type="Text"
                  placeholder="Ecris un text"
                  value={text}
                  // type="text"
                />
                <input
                  label="Outlined"
                  onChange={(e) => setPodcast(e.target.value)}
                  type="Titre"
                  placeholder="Le lien du podcast"
                  value={podcast}
                  // type="text"
                />
                <input type="submit" value="SEND" />
              </form>
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
      <div className="selectStyle">
        <select
          name="pets"
          id="pet-select"
          onChange={(e) => setPlace(e.target.value)}
        >
          <option value="">Lieu...</option>
          {GetUnique(newsData, "lieu").map((data) => {
            return (
              <option key={data.id} value={data.lieu}>
                {data.lieu}
              </option>
            );
          })}
        </select>

        <select
          name="pets"
          id="pet-select"
          onChange={(e) => setAtelier(e.target.value)}
        >
          <option value="">Atelier</option>
          {GetUnique(newsData, "typeAtelier").map((data) => {
            return (
              <option key={data.id} value={data.typeAtelier}>
                {data.typeAtelier}
              </option>
            );
          })}
        </select>
      </div>

      {newsData
        .filter(
          (data) =>
            data.lieu.includes(place) && data.typeAtelier.includes(atelier)
        )
        .slice(0)
        .reverse()
        .map((data) => {
          return <ExprimePost key={data.id} post={data} />;
        })}
    </div>
  );
}

export default Exprime;
