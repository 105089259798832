import React from "react";

function Contact() {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <p>Direction artistique : jeremy.ridel@fullfrontaltheatre.org</p>
      <p>Production et EAC : production@fullfrontaltheatre.org</p>
      <h3>
        <a href="https://www.jeremyridel.com/">FullFrontalTheatre</a>
      </h3>
    </div>
  );
}

export default Contact;
