import React from "react";
import Map from "../components/Map";

function Home() {
  return (
    <div className="home width">
      <Map className="test" />
    </div>
  );
}

export default Home;
