import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useCookies } from "react-cookie";

import Home from "./pages/Home";
import Test from "./pages/Test";
import Exprime from "./pages/Exprime";
import Atelier from "./pages/Atelier";
import Spectator from "./pages/Spectator";
import Workshop from "./pages/Workshop";
import Team from "./pages/Team";
import Clea from "./pages/Clea";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Logout from "./components/log/Logout";
import Login from "./components/log/Login";
import SignUp from "./components/log/SignUp";
import UserAccount from "./components/log/UserAccount";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  // const [cookies, setCookie] = useCookies(["user"]);
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/exprime" element={<Exprime />}></Route>
          <Route path="/ateliers" element={<Atelier />}></Route>
          <Route path="/spectator" element={<Spectator />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route path="/Clea" element={<Clea />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/workshop" element={<Workshop />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/logout" element={<Logout />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/test" element={<Test />}></Route>
          <Route path="/account" element={<UserAccount />}></Route>
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
      {/* <button onclick="topFunction()" id="myBtn" title="Go to top">
        Top
      </button> */}
      <ScrollToTop />
    </div>
  );
}

export default App;
