import { useState, useEffect } from "react";
import axios from "axios";

const useFetchLieux = () => {
  const [lieux, setLieux] = useState([]);

  useEffect(() => {
    const fetchLieux = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lieu`
        );
        setLieux(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des lieux :", error);
      }
    };

    fetchLieux();
  }, []);

  return lieux;
};

export default useFetchLieux;
