import React from "react";

function Team() {
  return (
    <div className="team">
      <h1>L'équipe</h1>
      <div className="teamVignette">
        <div className="roundedImage">
          <div className="rounded">
            <img className="content" src="/Photo Louison.webp" alt="" />
          </div>
          <div className="contentLeft">
            <h3>Louison Bergman - Coordinatrice du projet</h3>

            <p>
              Après des études à l'Université Panthéon Assas et à Paris
              Dauphine, Louison Bergman rejoint le Théâtre national de la
              Colline en tant que chargée de mécénat et de médiation culturelle.
              Au Théâtre du Châtelet, elle est responsable du développement des
              publics et pilote des projets mécénés à fort impact social (La
              Fabrique Citoyenne Artistique, les Robins des Bois). Aujourd'hui,
              elle accompagne des compagnies et structures culturelles dans leur
              développement et assure la coordination de projets culturels et
              humanitaires (dernièrement The Walk de Good Chance Theatre).
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImageRight">
          <div className="rounded">
            <img src="/David Hess.webp" alt="" />
          </div>
          <div className="contentRight">
            <h3>David Hess - Créateur sonore et intervenant artistique</h3>
            <p>
              David étudie d’abord la batterie, avant de se former au piano et à
              la composition jazz. Il obtient en 2013 une licence de réalisation
              sonore à l’EICAR. Il est aujourd’hui compositeur pour le spectacle
              vivant et travaille avec différents metteurs en scène comme
              Nathalie Bensard (La Rousse), Clara Cirera (Nice to meet you),
              Clotilde Labbé (Passerelles Théâtre), Jeanne Lepers (BLOC), ou
              Jérémy Ridel (FullFrontalTheatre). En parallèle de son métier de
              compositeur, David mène également des ateliers de création sonore
              en milieu scolaire avec La Pop (Paris, projet Tohu Bahut) et avec
              le Préau - Centre dramatique national (Culture s’anime en
              Normandie).
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImage">
          <div className="rounded">
            <img src="/img_1645.webp" alt="" />
          </div>
          <div className="contentLeft">
            <h3>Pierre Koestel - Auteur et intervenant artistique</h3>

            <p>
              Né en 1989, Pierre Koestel intègre le master de Création
              littéraire de l'Université Paris 8, puis le département Écrivain
              dramaturge de l'ENSATT dont il sort diplômé en 2018. Il a
              notamment écrit des textes à destination des adolescents : Loud,
              sélectionné par le comité de lecture du JTN, mis en espace par
              Matthieu Roy à Théâtre Ouvert et lauréat des Encouragements de
              l'Aide à la Création d'Artcena. Les Ecoeurchées pour l'Anima
              Compagnie (Mayenne), Basalte, une commande du Théâtre Am Stram
              Gram (Genève) mise en scène par Tamara Fischer dans le cadre du «
              Théâtre c'est (dans ta) classe », ou encore Elio pour la
              Fédération - Compagnie Philippe Delaigue (Lyon). Son dernier
              texte, La Ceriseraie, qui gravite autour de l'œuvre de Tchekhov,
              est créé au printemps 2021 à Cotonou dans une mise en scène de
              Marie Demesy. En parallèle, il anime régulièrement des ateliers
              d'écriture, notamment auprès de publics scolaires et d'étudiants.
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImageRight">
          <div className="rounded">
            <img src="/s200_chlo__lavalette.webp" alt="" />
          </div>
          <div className="contentRight">
            <h3>Chloé Lavalette - Dramaturge et Intervenante artistique</h3>
            <p>
              Travailleuse du texte et de la scène, Chloé Lavalette est
              doctorante en Arts du spectacle au sein du programme SACRe (PSL,
              ED540). Formée à la mise en scène et à la dramaturgie, elle
              recycle certains matériaux de sa recherche en installations ou en
              spectacles. Elle collabore ou a collaboré avec plusieurs metteurs
              en scène et collectifs, tels que la compagnie du Balèti ou le
              collectif Nash ; elle accompagne Jérémy Ridel à la dramaturgie de
              ses spectacles (FullFrontalTheatre) et Louise Vignaud (Cie La
              Résolue) en tant qu'assistante à la mise en scène. Elle co-fonde
              en 2017 l’atelier-laboratoire Performer la pensée, un espace de
              réflexion sur la performance comme outil épistémologique et medium
              pour les artistes et chercheurs.
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImage">
          <div className="rounded">
            <img src="/ImageFclean2.webp" alt="" />
          </div>
          <div className="contentLeft">
            <h3>Daniel Monino - Comédien et intervenant artistique</h3>

            <p>
              Titulaire d’un master en études théâtrales, il est formé au
              Théâtre National de la Colline . Il crée Festival Théâtre En
              Liberté de 2011 à 2016 à Montferrier sur Lez (34).Il participe en
              parallèle au projet Education & Proximité (La Colline - Théâtre
              National) entre 2015 et 2019 auprès de lycéens. En 2019/2020, il
              travaille avec le Théâtre du Châtelet sur la FabCA. Il dirige, par
              ailleurs, des ateliers de théâtre auprès d'amateurs depuis 2015
              dans la région Montpelliéraine auprès d'un public divers (enfants,
              adultes, seniors).
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImageRight">
          <div className="rounded">
            <img src="/Julie-Vanves-9.webp" alt="" />
          </div>
          <div className="contentRight">
            <h3>Angèle Peyrade - Comédienne et intervenante artistique</h3>
            <p>
              Comédienne, metteuse en scène et danseuse formée entre
              l'université Paris III Sorbonne nouvelle, la Colline - Théâtre
              National, le Théâtre du mouvement et divers stage de danse, elle
              travaille pour La Merise (Trappes) dans le cadre du programme
              Banlieues'Art en tant qu'artiste intervenante pour des classes de
              lycée de 2017 à 2019, et auprès de classes de primaires pour le
              Théâtre des Bergeries, Noisy-Le-Sec (Lire-Dire) depuis 2019. Elle
              intervient également plus ponctuellement autour des
              représentations du spectacle Si Mona naissait là ( Semaine de
              l'égalité au Lycée Français de Singapour, 2016). Elle mène
              également des actions artistiques auprès de Lycéens pour la
              Colline (Éducation et Proximité, en 2019-2020) et le Théâtre du
              Châtelet (Fabca, depuis 2019), et avec des collégiens pour le
              Théâtre Jacques Prévert à Aulnay-sous-bois depuis 2021.
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImage">
          <div className="rounded">
            <img src="/simon clean.webp" alt="" />
          </div>
          <div className="contentLeft">
            <h3>Simon Rembado - Comédien et intervenant artistique</h3>

            <p>
              Simon Rembado sort diplômé du CNSAD en 2016. Il est intervenu
              plusieurs années au sein du programme Éducation et Proximité,
              auprès de la Colline-Théâtre National, à destination de lycéens,
              et dans une classe UPE2A en collège. La DRAC
              Bourgogne-Franche-Comté lui attribue une résidence EAC en école
              primaire, puis il obtient son DE de professeur de théâtre en 2019.
              Actuellement, il est lauréat du programme Création en Cours des
              Ateliers Médicis, et intervient dans la Fabrique Citoyenne
              Artistique du Théâtre du Châtelet.
            </p>
          </div>
        </div>
      </div>

      <div className="teamVignette">
        <div className="roundedImageRight">
          <div className="rounded">
            <img src="/Untitled.webp" alt="" />
          </div>
          <div className="contentRight">
            <h3>Jérémy Ridel - Metteur en scène et intervenant artistique</h3>
            <p>
              Après des études à Paris 3 - Sorbonne Nouvelle et au Théâtre
              national de la Colline, Jérémy Ridel a créé plusieurs spectacles
              au sein de la compagnie FullFrontalTheatre en France et à
              l’étranger. Il participe en parallèle en tant qu’intervenant
              artistique à des projets d’actions artistiques auprès du Théâtre
              de la Colline (Education et proximité) entre 2015 et 2019 ainsi
              qu’auprès du Théâtre du Châtelet (FabCA). Il est Lauréat du
              programme Internationales Forum 2016 à Berlin et a mené un projet
              de recherche artistique au Carreau du temple et au Studio Théâtre
              de Vitry entre 2018 et 2020.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
