import axios from "axios";
import React from "react";
import { NavLink } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReorderIcon from "@material-ui/icons/Reorder";
import { useNavigate } from "react-router";

function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);
  const [userName, setUserName] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        {},
        { withCredentials: true }
      )
      .then(() => {
        setUserName(""); // Mettre à jour l'état pour refléter que l'utilisateur est déconnecté
        navigate("/login");
      })
      .catch((error) => {
        console.log("Erreur lors de la déconnexion :", error);
        // Gérer l'erreur de déconnexion ici
      });
  };
  const closeNavbar = () => {
    setExpandNavbar(false);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/userinfo`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data && response.data.username) {
          setUserName(response.data.username);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // L'utilisateur n'est pas connecté ou la session a expiré
          console.log("Utilisateur non connecté ou session expirée");
          // Ici, vous pouvez choisir de ne rien faire ou de définir un état spécifique
        } else {
          // Gérer les autres erreurs
          console.log(
            "Erreur lors de la récupération des informations utilisateur:",
            error
          );
        }
      });
  }, [location]); // Dépendance à `location` si vous souhaitez que cela s'exécute à chaque changement d'URL
  return (
    <div className="navbar" id={expandNavbar ? "open" : "close"}>
      <div className="navbarFlex">
        <Link to="/">
          <img
            id="logo"
            src="/fullfrontaltheatre.svg"
            alt="logo Full Frontal Theatre"
          />{" "}
        </Link>

        <h1 className="">TRAJECTOIRE(S)</h1>
        <div className="links navPolice">
          <Link to="/" onClick={closeNavbar}>
            Accueil
          </Link>
          <Link to="/exprime" onClick={closeNavbar}>
            {" "}
            Je m'exprime ici
          </Link>
          <Link to="/ateliers" onClick={closeNavbar}>
            {" "}
            Je découvre des ateliers{" "}
          </Link>
          <Link to="/spectator" onClick={closeNavbar}>
            {" "}
            Je suis spectateur
          </Link>
        </div>
        {userName ? (
          <>
            <div className="welcome">
              <NavLink exact to="/account" activeClassName="nav-active">
                <h2>{userName}</h2>
              </NavLink>
            </div>
            <a onClick={(e) => handleLogout(e)} className="myButton">
              LOGOUT
            </a>
          </>
        ) : (
          <></>
        )}
        <div className="toggleButton">
          <button
            onClick={() => {
              setExpandNavbar((prev) => !prev);
            }}
          >
            <ReorderIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
