import axios from "axios";
import React, { useEffect, useState } from "react";

const UserAccount = () => {
  const [user, setUser] = useState({ username: "", userId: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditUser] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/userinfo`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data) {
          setUser({
            username: response.data.username,
            userId: response.data.userId,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Si l'utilisateur n'est pas connecté ou si la session a expiré
          console.log("Utilisateur non connecté ou session expirée");
          setUser("");
          // Autres actions si nécessaire, comme rediriger vers la page de connexion
        } else {
          console.log(
            "Erreur lors de la récupération des informations utilisateur:",
            error
          );
        }
      });
  }, []);

  // Fonction pour obtenir les informations de l'utilisateur connecté !

  const handleChangePassword = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/auth/changePassword`,
        {
          currentPassword,
          newPassword,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data.message);
        setCurrentPassword("");
        setNewPassword("");
      })
      .catch((error) => {
        console.error("Erreur lors du changement de mot de passe:", error);
      });
  };

  const handleEdit = () => {
    const data = { username: editedUser ? editedUser : user.username };
    axios
      .put(`${process.env.REACT_APP_API_URL}/auth/${user.userId}`, data, {
        withCredentials: true,
      })
      .then(() => {
        setUser({ ...user, username: editedUser });
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour du profil:", error);
      });
  };

  const handleDelete = () => {
    if (window.confirm("Voulez-vous supprimer votre compte ?")) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/auth/${user.userId}`, {
          withCredentials: true,
        })
        .then(() => {
          window.location = "/";
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression du compte:", error);
        });
    }
  };

  return (
    <div>
      <div className="profil">
        <div className="sendPost">
          <h2>Account Settings</h2>
          <br />
          <br />
          <div>
            <h3>{user.username}</h3>
            <div>
              <h3>Change Password</h3>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current Password"
              />
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              <button onClick={handleChangePassword}>Change Password</button>
            </div>
            {isEditing ? (
              <textarea
                onChange={(e) => setEditUser(e.target.value)}
                autoFocus
                defaultValue={editedUser ? editedUser : user.username}
              ></textarea>
            ) : (
              <p>{editedUser ? editedUser : user.username}</p>
            )}
            <>
              {isEditing ? (
                <button onClick={handleEdit}>Valider</button>
              ) : (
                <button onClick={() => setIsEditing(true)}>
                  <i className="far fa-edit"></i>
                </button>
              )}
            </>
          </div>
          <button
            onClick={() => {
              if (window.confirm("Voulez-vous supprimer votre compte ?"))
                handleDelete();
            }}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
