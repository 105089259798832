import React, { useEffect } from "react";
import axios from "axios";

const ExprimeDelete = (id) => {
  // Cette fonction permet à l'admin de supprimer les posts
  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/posts/` + id.id, {
        withCredentials: true,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression du post:", error);
        // Ici, vous pouvez gérer l'erreur, par exemple en informant l'utilisateur que la suppression a échoué
      });
  };
  return (
    <button
      onClick={() => {
        if (window.confirm("Voulez-vous supprimer cet article ?"))
          handleDelete();
      }}
    >
      Supprimer
      <i className="far fa-trash-alt"></i>
    </button>
  );
};
export default ExprimeDelete;
