import axios from "axios";
import React, { useEffect, useState } from "react";

function Test() {
  const [newsData, setNewsData] = useState([]);
  const [lieu, setLieu] = useState([])
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/lieu/`).then((res) => {
      setNewsData(res.data);
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_API_URL}/lieu`,{
      lieu: lieu
    }).then(() => {
      setLieu("");
      getData();
    })
  };
  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          label="Outlined"
          onChange={(e) => setLieu(e.target.value)}
          type="Titre"
          placeholder="lieu"
          value={lieu}
          // type="text"
        />
        <input type="submit" value="send" />
      </form>
      {newsData.map((data) => {
        return <p>{data.lieu}</p>;
      })}
    </div>
  );
}
export default Test;

// import { useEffect, useState } from "react";

// function DeloreanClock() {
//   const [time, setTime] = useState(new Date());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTime(new Date());
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const hours = time.getHours();
//   const minutes = time.getMinutes();
//   const seconds = time.getSeconds();

//   return (
//     <div
//       className="delorean-clock"
//       style={{ backgroundImage: "url(chemin/vers/image.jpg)" }}
//     >
//       <div className="clock-display">
//         {hours}:{minutes}:{seconds}
//       </div>
//     </div>
//   );
// }
// export default DeloreanClock;
// import React, { useState } from "react";

// const PirateGame = () => {
//   // Définir l'état du jeu
//   const [health, setHealth] = useState(100);
//   const [gold, setGold] = useState(0);
//   const [inventory, setInventory] = useState([]);

//   // Fonction pour attaquer un ennemi et réduire sa santé
//   const attackEnemy = (enemyHealth) => {
//     // Générer un nombre aléatoire entre 1 et 10 comme dégâts infligés
//     const damage = Math.floor(Math.random() * 10) + 1;
//     setHealth(Math.max(health - damage, 0)); // Réduire la santé de l'ennemi en tenant compte de la limite de 0
//   };

//   // Fonction pour ramasser de l'or
//   const pickUpGold = (amount) => {
//     setGold(gold + amount);
//   };

//   // Fonction pour ramasser un objet
//   const pickUpItem = (item) => {
//     setInventory([...inventory, item]);
//   };

//   // Rendu de l'interface utilisateur du jeu
//   return (
//     <div>
//       <h1>Pirate Game</h1>
//       <div>Santé : {health}</div>
//       <div>Or : {gold}</div>
//       <div>Inventaire : {inventory.join(", ")}</div>
//       <button onClick={() => attackEnemy(50)}>Attaquer l'ennemi</button>
//       <button onClick={() => pickUpGold(10)}>Ramasser de l'or</button>
//       <button onClick={() => pickUpItem("épée en acier")}>
//         Ramasser une épée en acier
//       </button>
//     </div>
//   );
// };

// export default PirateGame;

// import React, { useState } from 'react';
// import "./test.css";

// function PlantTracker() {
//   const [plants, setPlants] = useState([
//     {
//       name: "Ficus",
//       lastWatered: "2022-12-22",
//       nextWatering: "2022-12-25",
//       waterNeed: "high",
//       lightNeed: "low",
//     },
//     {
//       name: "Broméliacée",
//       lastWatered: "2022-12-20",
//       nextWatering: "2022-12-23",
//       waterNeed: "medium",
//       lightNeed: "high",
//     },
//   ]);

//   function handleSubmit(event) {
//     event.preventDefault();

//     const formData = new FormData(event.target);
//     const newPlant = {
//       name: formData.get("name"),
//       lastWatered: formData.get("lastWatered"),
//       nextWatering: formData.get("nextWatering"),
//       waterNeed: formData.get("waterNeed"),
//       lightNeed: formData.get("lightNeed"),
//     };

//     setPlants([...plants, newPlant]);
//   }

//   function handleDelete(index) {
//     setPlants(plants.filter((plant, i) => i !== index));
//   }

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <label htmlFor="name">Nom :</label>
//         <input type="text" id="name" name="name" />
//         <br />
//         <label htmlFor="lastWatered">Dernier arrosage :</label>
//         <input type="date" id="lastWatered" name="lastWatered" />
//         <br />
//         <label htmlFor="nextWatering">Prochain arrosage :</label>
//         <input type="date" id="nextWatering" name="nextWatering" />
//         <br />
//         <label htmlFor="waterNeed">Besoin en eau :</label>
//         <select id="waterNeed" name="waterNeed">
//           <option value="low">Faible</option>
//           <option value="medium">Moyen</option>
//           <option value="high">Elevé</option>
//         </select>
//         <br />
//         <label htmlFor="lightNeed">Besoin en lumière :</label>
//         <select id="lightNeed" name="lightNeed">
//           <option value="low">Faible</option>
//           <option value="medium">Moyen</option>
//           <option value="high">Elevé</option>
//         </select>
//         <br />
//         <button type="submit">Ajouter</button>
//       </form>
//       <ul>
//         {plants.map((plant, index) => (
//           <li key={plant.name}>
//             {plant.name} - Dernier arrosage : {plant.lastWatered} - Prochain
//             arrosage : {plant.nextWatering} - Besoin en eau : {plant.waterNeed}{" "}
//             - Besoin en lumière : {plant.lightNeed}{" "}
//             <button onClick={() => handleDelete(index)}>Supprimer</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default PlantTracker;

// import React, { useState } from "react";

// function TodoList() {
//   const [tasks, setTasks] = useState([
//     {
//       title: "Acheter du pain",
//       deadline: "2022-12-22",
//       completed: false,
//     },
//     {
//       title: "Faire le ménage",
//       deadline: "2022-12-23",
//       completed: false,
//     },
//   ]);

//   function handleSubmit(event) {
//     event.preventDefault();

//     const formData = new FormData(event.target);
//     const newTask = {
//       title: formData.get("title"),
//       deadline: formData.get("deadline"),
//       completed: false,
//     };

//     setTasks([...tasks, newTask]);
//   }

//   function handleDelete(index) {
//     setTasks(tasks.filter((task, i) => i !== index));
//   }

//   function handleToggle(index) {
//     setTasks(
//       tasks.map((task, i) => {
//         if (i === index) {
//           return {
//             ...task,
//             completed: !task.completed,
//           };
//         }
//         return task;
//       })
//     );
//   }

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <label htmlFor="title">Titre :</label>
//         <input type="text" id="title" name="title" />
//         <br />
//         <label htmlFor="deadline">Deadline :</label>
//         <input type="date" id="deadline" name="deadline" />
//         <br />
//         <button type="submit">Ajouter</button>
//       </form>
//       <ul>
//         {tasks.map((task, index) => (
//           <li key={task.title}>
//             <span
//               style={{
//                 textDecoration: task.completed ? "line-through" : "none",
//               }}
//             >
//               {task.title} ({task.deadline})
//             </span>
//             <button onClick={() => handleToggle(index)}>
//               {task.completed ? "Reouvrir" : "Compléter"}
//             </button>
//             <button onClick={() => handleDelete(index)}>Supprimer</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default TodoList;

// import React, { useState, useEffect } from "react";
// import "./test.css";

// const App = () => {
//   const [profile, setProfile] = useState({
//     name: "John Doe",
//     profession: "Web Developer",
//     profileImage: "https://picsum.photos/200",
//     bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare magna eros, eu pellentesque tortor vestibulum ut. Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.",
//     skills: ["HTML", "CSS", "JavaScript", "React"],
//   });
//   const [currentSkill, setCurrentSkill] = useState(0);
//   const [matrixText, setMatrixText] = useState("");

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSkill((prevSkill) => (prevSkill + 1) % profile.skills.length);
//     }, 1000);
//     return () => clearInterval(interval);
//   }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setMatrixText((prevText) => prevText + "0");
//     }, 50);
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="profile">
//       <img src={profile.profileImage} alt="Profile" />
//       <h1>{profile.name}</h1>
//       <h2>{profile.profession}</h2>
//       <p>{profile.bio}</p>
//       <h3>Compétences :</h3>
//       <ul>
//         {profile.skills.map((skill, index) => (
//           <li
//             key={index}
//             className={index === currentSkill ? "visible" : "hidden"}
//           >
//             {skill}
//           </li>
//         ))}
//       </ul>

//     </div>
//   );
// };

// export default App;

// import React, { useState } from "react";
// import axios from "axios";

// const App = () => {
//   const [weather, setWeather] = useState(null);

//   const getWeather = async () => {
//     const API_KEY = "YOUR_API_KEY";
//     const city = "Marseille";
//     const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${API_KEY}`;
//     const response = await axios.get(url);
//     setWeather(response.data);
//   };

//   return (
//     <div>
//       <button onClick={getWeather}>Afficher la météo à Marseille</button>
//       {weather ? (
//         <div>
//           <p>Température : {weather.main.temp}°C</p>
//           <p>Description : {weather.weather[0].description}</p>
//         </div>
//       ) : (
//         <p>Chargement de la météo...</p>
//       )}
//     </div>
//   );
// };

// export default App;
// import React, { useState } from 'react';

// const ChatBot = () => {
//   // État pour stocker les messages de l'utilisateur et du bot
//   const [messages, setMessages] = useState([]);

//   // État pour stocker le message en cours de rédaction par l'utilisateur
//   const [currentMessage, setCurrentMessage] = useState("");

//   // Fonction pour ajouter un message de l'utilisateur à la liste
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setMessages([...messages, { sender: "utilisateur", text: currentMessage }]);
//     setCurrentMessage("");

//     // Traiter le message de l'utilisateur et ajouter une réponse du bot
//     handleUserMessage(currentMessage);
//   };

//   // Fonction pour ajouter une réponse du bot à la liste
//   const addBotResponse = (message) => {
//     setMessages([...messages, { sender: "bot", text: message }]);
//   };

//   // Fonction pour traiter le message de l'utilisateur et ajouter une réponse du bot
//   const handleUserMessage = (message) => {
//     const lowerCaseMessage = message.toLowerCase();
//     if (lowerCaseMessage.includes("bonjour")) {
//       addBotResponse("Bonjour, comment allez-vous aujourd'hui ?");
//     } else if (lowerCaseMessage.includes("bien")) {
//       addBotResponse(
//         "C'est super ! Je suis là pour vous remonter le moral si vous en avez besoin."
//       );
//     } else if (lowerCaseMessage.includes("mal")) {
//       addBotResponse(
//         "Je suis désolé de l'entendre. Vous pouvez toujours compter sur moi pour vous remonter le moral !"
//       );
//     } else {
//       addBotResponse(
//         "Je ne suis pas sûr de comprendre ce que vous voulez dire. Pouvez-vous reformuler votre message ?"
//       );
//     }
//   };

//   return (
//     <div>
//       <h1>Chatbot de motivation</h1>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={currentMessage}
//           onChange={(e) => setCurrentMessage(e.target.value)}
//         />
//         <button type="submit">Envoyer</button>
//       </form>
//       <div>
//         {messages.map((message, index) => (
//           <div key={index} className={message.sender}>
//             {message.text}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ChatBot;

// import React, { useState, useEffect, useRef } from 'react';

// const SpecialCharFollowMouse = () => {
//   // État pour stocker la position actuelle de la souris
//   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

//   // Référence pour stocker l'intervalle de temps qui génère les caractères spéciaux
//   const intervalRef = useRef();

//   // Fonction pour mettre à jour la position de la souris
//   const handleMouseMove = e => {
//     setMousePosition({ x: e.clientX, y: e.clientY });
//   }

//   // Effect qui s'exécute lorsque le composant est monté et qui démarre l'intervalle de temps
//   useEffect(() => {
//     intervalRef.current = setInterval(() => {
//       console.log("Génération d'un caractère spécial");
//     }, 1000);

//     // Définir le gestionnaire de mouvement de la souris
//     document.addEventListener('mousemove', handleMouseMove);

//     // Effect qui s'exécute lorsque le composant est démonté et qui arrête l'intervalle de temps
//     return () => {
//       clearInterval(intervalRef.current);
//       document.removeEventListener('mousemove', handleMouseMove);
//     }
//   }, []);

//   return (
//     <div>
//       Suivez la souris avec votre curseur pour voir apparaître des caractères spéciaux toutes les 1 secondes.
//       <br />
//       Position actuelle de la souris : {mousePosition.x}, {mousePosition.y}
//     </div>
//   );
// }

// export default SpecialCharFollowMouse;
// import axios from "axios";
// import React, { useEffect } from "react";
// import { useState } from "react";

// function Test() {
//   const [newsData, setNewsData] = useState([]);
//   useEffect(() => {
//     getData();
//   }, []);

//   const getData = () => {
//     axios.get(`${process.env.REACT_APP_API_URL}/posts/`).then((res) => {
//       setNewsData(res.data);
//     });
//   };
//   console.log(newsData);

//   return (
//     newsData
//     .map((data) => {
//         return <div key={data.id}>{data.title}</div>;
//     })
//   );
// }

// export default Test;

// import React, { useState } from 'react';

// // Tableau de thèmes possibles
// const themes = [
//   'une journée au zoo',
//   "un voyage dans l'espace",
//   'une soirée pyjama',
//   'un jour de pluie',
//   "une fête d'anniversaire surprise",
//   'une journée à la plage'
// ];

// // Tableau de contraintes possibles
// const constraints = [
//   'utiliser seulement les mains pour parler',
//   'ne pas utiliser de mots de plus de deux syllabes',
//   'ne pas utiliser de mots commençant par la lettre "s"',
//   "faire semblant d'être un animal de la savane",
//   'ne pas utiliser de mots décrivant des émotions',
//   'utiliser un accent ou un dialecte différent du vôtre'
// ];

// const ThemeGenerator = () => {
//   // État pour stocker le thème généré
//   const [theme, setTheme] = useState('');

//   // État pour stocker la contrainte générée
//   const [constraint, setConstraint] = useState('');

//   // Fonction pour générer un nouveau thème et une nouvelle contrainte au hasard
//   const generateThemeAndConstraint = () => {
//     const newTheme = themes[Math.floor(Math.random() * themes.length)];
//     const newConstraint = constraints[Math.floor(Math.random() * constraints.length)];
//     setTheme(newTheme);
//     setConstraint(newConstraint);
//   }

//   return (
//     <div>
//       <h2>Thème généré :</h2>
//       <p>{theme}</p>
//       <h2>Contrainte générée :</h2>
//       <p>{constraint}</p>
//       <button onClick={generateThemeAndConstraint}>Générer un nouveau thème et une nouvelle contrainte</button>
//     </div>
//   );
// }

// export default ThemeGenerator;
