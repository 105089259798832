function getUnique(Data, comp) {
  // store the comparison  values in array
  const unique = Data.map((e) => e[comp])
    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    //elinimate the final indexes & return unique objects
    .filter((e) => Data[e])
    .map((e) => Data[e]);
  return unique;
}

export default getUnique;
